import { LogLevel } from '@azure/msal-browser';

export const environment = {
  production: true
};

export const aadScopesUserImpersonation = "api://mycloudsecurity-ussprod/user_impersonation"; 
export const azureAdCLientId = "b5694fb3-fdc7-474a-9f0a-2143b62fed2d";
export const redirectUri = "https://uss.mycloudsecurity.siemens.cloud/";
export const postLogoutRedirectUri = "https://uss.mycloudsecurity.siemens.cloud/"
export const apiUrl = "https://api.uss.mycloudsecurity.siemens.cloud/";
export const logLevel = LogLevel.Error;
export const PiiEnabled = true;